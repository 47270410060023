<template>
	<modal-drawer title="DICOM Tags" from-right @close="$emit('close')">
		<div class="inner-wrapper">
			<div class="image-container" :style="{ backgroundImage: 'url(' + previewImageUrl + ')' }"></div>
			<dicom-tag-table :tags="dicomTags" />
		</div>
	</modal-drawer>
</template>

<script>
import ModalDrawer from '@components/ModalDrawer'
import DicomTagTable from '@components/DicomTagTable'

export default {
	name: 'DicomTagsDrawer',
	components: {
		ModalDrawer,
		DicomTagTable,
	},
	props: {
		canvas: {
			type: Object,
			required: true,
		},
		previewImageUrl: {
			type: String,
			required: true,
		},
		dicomTags: {
			type: Array,
			required: true,
		},
	},
}
</script>

<style scoped>
.inner-wrapper {
	padding: 1rem 1.25rem;
	max-width: 100%;
	overflow-x: auto;
}
.image-information {
	margin-bottom: 10px;
}
.image-container {
	width: 100%;
	height: 200px;
	margin-bottom: 25px;
	background-color: black;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}
</style>
