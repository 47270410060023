<template>
	<data-table :is-hoverable="false">
		<thead v-if="level === 0">
			<tr>
				<th>Group</th>
				<th>Element</th>
				<th>Name</th>
				<th>Value</th>
			</tr>
		</thead>
		<tbody>
			<template v-for="tag in tags">
				<tr :key="`${level}-${parseHex(tag.Group)}-${parseHex(tag.Element)}-${Math.random()}`">
					<td class="shrink-column">
						<code>{{ parseHex(tag.Group) }}</code>
					</td>
					<td class="shrink-column">
						<code>{{ parseHex(tag.Element) }}</code>
					</td>
					<td class="tag-name">{{ tag.Name }}</td>
					<td class="tag-value">
						{{ tag.Value }}
						<em v-if="!tag.Value && tag.Value !== 0" class="empty">Empty</em>
					</td>
				</tr>
				<tr
					v-if="tag.Children"
					:key="
						`${level}-${parseHex(tag.Group)}-${parseHex(tag.Element)}-children-${Math.random()}`
					"
					class="child-container"
				>
					<td colspan="4">
						<dicom-tag-table v-if="tag.Children" :tags="tag.Children" :level="level + 1" />
					</td>
				</tr>
			</template>
		</tbody>
	</data-table>
</template>

<script>
import DataTable from '@components/DataTable'
export default {
	name: 'DicomTagTable',
	components: {
		DataTable,
	},
	props: {
		tags: {
			type: Array,
			required: true,
		},
		level: {
			type: Number,
			default: 0,
		},
	},
	methods: {
		parseHex(decimal) {
			if (!decimal) return
			let hex = decimal.toString(16).toUpperCase()
			while (hex.length < 4) {
				hex = '0' + hex
			}
			return hex
		},
	},
}
</script>

<style lang="scss" scoped>
code {
	line-height: 1; /* IE */
}
.tag-value {
	.empty {
		user-select: none;
		opacity: 0.5;
	}
}
table td,
table th {
	padding: 0.5em 0.25em !important;
	word-wrap: normal !important;
	vertical-align: bottom !important;
}
.data-table th {
	position: initial; // sticky headers act weird with nested dicom tags
}
.child-container > td {
	background: transparent !important;
	font-size: 1em;
	table {
		tr > td {
			width: auto;
			padding-left: 2em !important;
		}
		tr > td.shrink-column {
			width: 0.1%;
			white-space: nowrap;
		}
		tr:first-child td {
			border-top: 0;
		}
	}
}
</style>
